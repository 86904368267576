import { ResultPrice } from '@empathyco/x-types';
import { EmpathyDemoPlatformResult } from '../adapter';

/**
 * Maps the price.
 *
 * @param rawResult - The result to map the price from.
 * @returns The mapped price.
 */
export function mapPrice(rawResult: EmpathyDemoPlatformResult): ResultPrice {
  const value: number = rawResult.price;
  const originalValue: number = rawResult.__prices?.previous?.value ?? rawResult.price;
  const futureValue: number = rawResult.__prices?.future?.value ?? rawResult.price;

  return {
    value,
    originalValue,
    futureValue,
    hasDiscount: value < originalValue
  };
}
